.wrapper{
  margin-bottom: 60px;
}
.wrapper img{
    width: 200px;
}
.title{
    margin-top: 20px;
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 800;
    font-size: 96px;
    color: #2662B3;
}

.text{
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 800;
    font-size: 32px;
    line-height: 39px;
    text-align: center;
    color: #2662B3;
    margin-top: 25px;
}